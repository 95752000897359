import React, { useState } from "react";
import styles from "./Info.module.scss";

export default function Info() {
  const [show, setShow] = useState(false);

  const handleClickShow = () => {
    setShow((prevState) => !prevState);
  };

  return (
    <div className={styles.container}>
      <div className={styles.box} onClick={handleClickShow}>
        {show ? (
          <div className={styles.content}>
            <div className={styles.title}>
              Hi, I'm Jay, a Vancouver based Full Stack Web Developer.
            </div>

            <div className={styles.bio}>
              I am driven by the constant search for the juncture between
              aesthetic beauty and technical performance. I believe a genuine
              understanding of other people can lead to impactful connections
              between human and technology, which in turn will result in
              improvements in how we live and communicate.
            </div>

            <hr />

            <div className={styles.skillset}>
              <b>Frameworks:</b> React, React Native, Vue, Rails, Express,
              Bootstrap, JQuery
              <br />
              <b>Languages:</b> JavaScript, Ruby, HTML, CSS
              <br />
              <b>Media:</b> Adobe XD, Photoshop, Illustrator, Lightroom,
              Premiere
              <br />
              <b>Version Control:</b> Git
              <br />
              <b>Testing:</b> Jest, Cypress, Mocha & Chai, Rspec
              <br />
              <b>Database:</b> Postgres, SQL
            </div>
          </div>
        ) : (
          <img src="./images/027A4610.jpg" alt="my photographs" />
        )}
      </div>
    </div>
  );
}
