import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

export default function Navbar({ toggleDropdown, menuDisplay }) {
  return (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/" className={styles.index}>
          Jay Z
        </Link>
        <Link to="/work" className={styles.work}>
          Work
        </Link>
        <Link to="/info" className={styles.info}>
          Info
        </Link>
        {menuDisplay ? (
          <CloseIcon className={styles.menu} onClick={toggleDropdown} />
        ) : (
          <MenuIcon className={styles.menu} onClick={toggleDropdown} />
        )}
      </div>

      <div className={styles.overlay}></div>
    </div>
  );
}
