import React from "react";

export default function Intro({ variables, handleOnClick }) {
  return (
    <div className="intro">
      <div>Hi, I'm Jay.</div>
      <div style={{ cursor: "pointer" }} onClick={handleOnClick}>
        {variables[variables.c]}
      </div>
    </div>
  );
}
