import React from "react";
import styles from "./Container.module.scss";
import Card from "./Card";
export default function Container({ projects }) {
  return (
    <div className={styles.container}>
      {projects.map((el) => (
        <Card
          key={el.ID}
          link={el.link}
          cover={el.cover}
          code={el.code}
          desc={el.desc}
          stack={el.stack}
        />
      ))}
    </div>
  );
}
