import React from "react";
import styles from "./Sidebar.module.scss";

export default function Sidebar(props) {
  return (
    <div className={styles.container}>
      <ul>
        <li>
          <a
            href="https://github.com/hollowic"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.gh}
          >
            GH
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/jay-zhou-2a65aab9/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.lk}
          >
            LK
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/rlpple/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.in}
          >
            IN
          </a>
        </li>
      </ul>
    </div>
  );
}
