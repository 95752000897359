import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Container from "./components/Container";
import Intro from "./components/Intro";
import Info from "./components/Info";
import "./App.scss";

function App() {
  const [projects, setProjects] = useState([
    {
      ID: 1,
      link: "https://interview-sch.netlify.com/",
      cover: "./images/Interview-Scheduler.png",
      code: "https://github.com/hollowic/scheduler",
      desc:
        "The interview scheduler is a single page application that enables users to create, edit and delete interview appointments with mentors combining a concise API with a WebSocket server for realtime interaction.",
      stack:
        "React, Node, Express, Postgres, WebSocket, SCSS, Storybook, Jest, Cypress.",
    },
    {
      ID: 2,
      link: "https://fancycolors.netlify.com/",
      cover: "./images/FancyColors.png",
      code: "https://github.com/hollowic/fancy-colors",
      desc:
        "Fancy colors is a clone of a popular color palette web application called coolors. (WIP)",
      stack: "React, Node, Express, Postgres, SCSS, Storybook, Jest.",
    },
    {
      ID: 3,
      link: "https://www.youtube.com/watch?v=a2kW34n9_x0&feature=youtu.be",
      cover: "./images/Locus.png",
      code: "https://github.com/hollowic/locus",
      desc:
        "Locus is a mobile application for sharing photographs and displaying their geolocation relative to the user. Using an image recognition API to autogenerate the category keywords for tags and extracting the exif data from the images such as aperture, exposure and geolocation.",
      stack: "React Native, AWS, Expo, Node, Express, WebSockets.",
    },
    {
      ID: 4,
      link: "https://my-personal-music-player.netlify.app/",
      cover: "./images/Music-Player.png",
      code: "https://github.com/hollowic/music-player",
      desc:
        "A personal music player built using Spotify and Genius’ API. Features include: OAuth, fetching lyrics, displaying BPM and progression bar.",
      stack: "React, Node, Express, SCSS.",
    },
    {
      ID: 5,
      link: "https://notes-recource-wall.herokuapp.com/",
      cover: "./images/Notes.png",
      code: "https://github.com/hollowic/midterm",
      desc:
        "A learning resource sharing app that allows users to save, like and rate tutorials, blog posts and videos in a central place that is publicly available to any user.",
      stack: "JQuery, Node, Express, Postgres, SCSS, EJS.",
    },
    {
      ID: 6,
      link: "https://tiny-application.herokuapp.com/login",
      cover: "./images/TinyApp.png",
      code: "https://github.com/hollowic/TinyApp",
      desc:
        "TinyApp is a multipage server-side rendering application that allows users to shorten long URLs much like TinyURL.com and bit.ly do. Leveraging the EJS templating engine and following the RESTful API design conventions.",
      stack: "Node, Express, EJS, HTML, CSS.",
    },
  ]);

  const [variables, setVariables] = useState({
    1: "I develop 🧑🏻‍💻 software and websites.",
    2: "I love photography 📷 and video games.",
    3: "I listen 🎧 to a lot of music.",
    4: "I will be a student 📖 forever.",
    5: "I drink my coffee ☕ black.",
    c: 1,
  });

  const handleOnClick = () => {
    setVariables((prevState) => ({
      ...prevState,
      c: prevState.c % 5 ? prevState.c + 1 : 1,
    }));
  };

  const [menuDisplay, setMenuDisplay] = useState(false);

  const toggleDropdown = () => {
    setMenuDisplay((prevState) => !prevState);
  };

  const reportWindowSize = () => {
    if (window.innerWidth >= 768) {
      setMenuDisplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);
  }, []);

  return (
    <Router>
      <Navbar toggleDropdown={toggleDropdown} menuDisplay={menuDisplay} />
      <Sidebar />

      {menuDisplay && (
        <div className="overlay">
          <Link to="/" onClick={toggleDropdown}>
            Jay Z
          </Link>
          <Link to="/work" onClick={toggleDropdown}>
            Work
          </Link>
          <Link to="/info" onClick={toggleDropdown}>
            Info
          </Link>
        </div>
      )}

      <Switch />
      <Route key="index" path="/" exact>
        <Intro variables={variables} handleOnClick={handleOnClick} />
      </Route>

      <Route key="work" path="/work" exact>
        <Container projects={projects} />
      </Route>

      <Route key="info" path="/info" exact>
        <Info />
      </Route>

      <Switch />
    </Router>
  );
}

export default App;
