import React from "react";
import styles from "./Card.module.scss";
import Button from "@material-ui/core/Button";

export default function Card({ link, cover, code, desc, stack }) {
  return (
    <div className={styles.card}>
      <img src={cover} alt="Project cover" />
      <div className={styles.content}>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.stack}>{stack}</div>
      </div>

      <Button className={styles.demo} href={link} target="_blank">
        Demo
      </Button>
      <Button className={styles.code} href={code} target="_blank">
        Code
      </Button>
    </div>
  );
}
